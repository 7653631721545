export interface User {
  name: string;
  id: string;
}

export interface LoggedIn {
  principal: User;
  isVerified: boolean;
  token: string;
  expireAt: Date;
}

export type Guest = 'guest';
export type SessionState = Guest | LoggedIn;

export const init: () => SessionState = () => 'guest';
