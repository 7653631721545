import React, { useEffect, useState } from 'react';
import {
  format, addMonths, eachDayOfInterval, startOfMonth, endOfMonth, addDays,
} from 'date-fns';
import ja from 'date-fns/locale/ja';
import {
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
  Paper,
  AppBar,
  Table, TableBody, TableCell, TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useReactRouter from 'use-react-router';
import useForm from 'react-hook-form';
import { usePrivateApi } from '../api/useApi';
import {
  ScheduleFrameApi, ScheduleFrameResponse,
  CounselingApi, UpdateCounselingRequest, CounselingReservationResponse,
} from '../generated';

const Content = () => {
  interface Frame {
    date: Date;
    hour: number;
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  const { api: scheduleFrameApi } = usePrivateApi(ScheduleFrameApi);
  const { api: counselingApi } = usePrivateApi(CounselingApi);
  const [tabValue, setTabValue] = useState(0);
  const [resScheduleFrames, setResScheduleFrames] = useState<Array<ScheduleFrameResponse>>();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFrame, setSelectedFrame] = useState<Frame>();
  const [counseling, setCounseling] = useState<CounselingReservationResponse>();
  const { history } = useReactRouter<{ reservationId: string }>();
  const useStyles = makeStyles(() => ({
    tab: {
      fontWeight: 'bold',
      fontSize: '2em',
      color: '#ffffff',
    },
    tableContainer: {
      width: '100%',
      overflow: 'auto',
      webkitOverflowScrolling: 'touch',
    },
    title: {
      padding: 10,
    },
    commentTextField: {
      width: 350,
    },
    inputFormPaper: {
      margin: 'auto',
      marginTop: 20,
      marginBottom: 20,
      maxWidth: 1300,
    },
    formGrid: {
      padding: 20,
    },
    cell: {
      padding: '14px 20px 14px 16px',
    },
  }));

  const daysOfOperableMonth = [0, 1].map(plusMonth => addMonths(new Date(), plusMonth));
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<{}>, newTabValue: number) => {
    setTabValue(newTabValue);
  };
  const startHour = 10;
  const endHour = 21;
  const selectableHours = [...Array(endHour - startHour)].map((_, i) => (i + startHour));
  const {
    register, handleSubmit, errors, triggerValidation, getValues,
  } = useForm();
  function TabPanel(props: TabPanelProps) {
    const {
      children, value, index, ...other
    } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
    );
  }

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function isEnableDay(day: Date) {
    if ([0, 6].includes(day.getDay())) { return false; } // 土日
    if (addDays(new Date(), 6) > day) { return false; }
    return true;
  }

  useEffect(() => {
    counselingApi.counselingsMyselfGet().then(res => {
      if (res.data.reservationTimestamp) { history.push('/counselings/complete'); }
      setCounseling(res.data);
    });
    scheduleFrameApi.scheduleFramesGet().then(res => {
      setResScheduleFrames(res.data.scheduleFrames);
    });
  }, []);

  function scheduleFrameIds(day: Date, hour: number): Array<number> {
    if (resScheduleFrames === undefined) { return []; }
    const fileteredFrames = resScheduleFrames.filter(frame => (frame.scheduleDate === format(day, 'yyyy-MM-dd')) && (frame.frameTime === hour * 2));
    return fileteredFrames.map(frame => frame.id);
  }

  const handleClickOpenDialog = async (e: React.MouseEvent<HTMLButtonElement>) => {
    // TODO: 後でまともな方法にリファクタリングする
    const date = new Date(e.currentTarget.value.split(',')[0]);
    const hour = Number(e.currentTarget.value.split(',')[1]);
    setSelectedFrame({ date, hour });
    const isValid = await triggerValidation();
    if (isValid) {
      setOpenDialog(true);
    } else { window.scroll(0, 0); }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmitWithoutSelect = () => {
    setOpenDialog(true);
    setSelectedFrame(undefined);
  };

  const formatLocale = (date: Date, hour: number) => `${format(new Date(date), 'PPPP', { locale: ja })} ${hour}:00 - `;

  const onSubmit = () => {
    const values = getValues();
    let frameId: number | undefined;
    let reservationTimestamp: Date | undefined;

    if (selectedFrame !== undefined) {
      // 予定がどこも合わないケースなど、スケジュール登録なしに連絡先と備考欄だけ更新するケースもある
      const selectedFrameIds = scheduleFrameIds(selectedFrame.date, selectedFrame.hour);
      // 複数のカウンセラーが同じスケジュールを登録している場合はクライアント側でランダムに選ぶ
      frameId = selectedFrameIds[Math.floor(Math.random() * selectedFrameIds.length)];
      reservationTimestamp = selectedFrame.date;
      reservationTimestamp.setHours(selectedFrame.hour);
      reservationTimestamp.setMinutes(0);
      reservationTimestamp.setSeconds(0);
    }
    const updateCounseling: UpdateCounselingRequest = {
      scheduleFrameId: frameId,
      reservationTimestamp,
      emergencyContact: values.emergencyContact as string,
      employeeComment: values.employeeComment as string,
    };
    if (typeof counseling === 'undefined') {
      return;
    }
    counselingApi.counselingsReservationIdPut(
      counseling.counselingReservationId, updateCounseling,
    ).then(() => history.push('/counselings/complete')).finally(() => setOpenDialog(false));
  };

  const emergencyContactRef = register({
    required: '必須項目です',
    minLength: {
      value: 8,
      message: '8文字以上必要です',
    },
  });
  const employeeCommentlRef = register({
    maxLength: {
      value: 600,
      message: '600文字以下で記入をお願いします',
    },
  });

  if (counseling === undefined) { return (<></>); }

  return (
    <>
      <form>
        <Paper className={classes.inputFormPaper}>

          <Box display="flex">
            <Box flexGrow={1}>
              <Typography variant="h3" component="h1" className={classes.title}>
            面談調整
              </Typography>
            </Box>
          </Box>

          <Grid
            container
            direction="row"
          >
            <Grid item className={classes.formGrid}>
              <Typography variant="h6" component="h3">緊急連絡先(必須)</Typography>
              <TextField
                type="text"
                name="emergencyContact"
                placeholder="000-0000-0000"
                defaultValue={counseling.emergencyContact}
                inputRef={emergencyContactRef}
              />
              {
              errors.emergencyContact && (
                <FormHelperText error>
                  {errors.emergencyContact.message}
                </FormHelperText>
              )
            }
            </Grid>
            <Grid item className={classes.formGrid}>
              <Typography variant="h6" component="h3">備考欄</Typography>
              <TextField
                type="text"
                name="employeeComment"
                multiline
                rows="4"
                defaultValue={counseling.employeeComment}
                className={classes.commentTextField}
                inputRef={employeeCommentlRef}
              />
              {
              errors.employeeComment && (
                <FormHelperText error>
                  {errors.employeeComment.message}
                </FormHelperText>
              )
            }
            </Grid>
          </Grid>
          <Grid className={classes.formGrid}>
            <Button
              variant="contained"
              disableFocusRipple
              disableRipple
              onClick={handleSubmit(handleSubmitWithoutSelect)}
            >
            指定できる日程がない
            </Button>
          </Grid>
        </Paper>
        <Paper className={classes.inputFormPaper}>
          <AppBar position="fixed" style={{ position: 'relative' }}>
            <Tabs value={tabValue} onChange={handleChange} aria-label="simple tabs">
              {daysOfOperableMonth.map((day, index) => (
                <Tab label={format(day, 'yyyy/MM')} {...a11yProps(index)} className={classes.tab} />))
            }
            </Tabs>
          </AppBar>
          <Paper className={classes.tableContainer}>
            {daysOfOperableMonth.map((day, index) => (
              <TabPanel value={tabValue} index={index}>
                <Table aria-label="simple table">
                  <TableBody>
                    {(eachDayOfInterval({ start: startOfMonth(day), end: endOfMonth(day) }))
                      .filter(d => isEnableDay(d)).map(d => (
                        <TableRow key={`${d}`}>
                          <TableCell className={classes.cell}>{format(d, 'dd(E)', { locale: ja })}</TableCell>
                          {selectableHours.map(hour => (
                            <TableCell key={`${d}${hour}`} className={classes.cell}>
                              <p>
                                {hour}
:00-
                              </p>
                              <Button
                                variant="contained"
                                disableFocusRipple
                                disableRipple
                                disabled={scheduleFrameIds(d, hour).length === 0}
                                onClick={handleClickOpenDialog}
                                value={`${format(d, 'yyyy-MM-dd')},${hour}`}
                              >
                                {(scheduleFrameIds(d, hour).length > 0) ? '登録' : ''}
                              </Button>
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
              }
                  </TableBody>
                </Table>
              </TabPanel>

            ))}

          </Paper>
        </Paper>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">面談設定の確認</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              「
              {' '}
              { selectedFrame ? formatLocale(selectedFrame.date, selectedFrame.hour) : '指定可能な面談がない(備考欄に理由の記載をお願い致します)'}
」で面談を予約します。よろしいですか？
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
            キャンセル
            </Button>
            <Button onClick={handleCloseDialog && onSubmit} color="primary" autoFocus>
            登録する
            </Button>
          </DialogActions>
        </Dialog>

      </form>
    </>
  );
};

export const CounselingReservationPage = Content;
