import React, { useState } from 'react';
import firebase from 'firebase';
import {
  Box, Button, Grid, TextField, CircularProgress, FormHelperText, Typography,
} from '@material-ui/core';
import useForm from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { clearRedirect } from '../../redux/redirect/RedirectAction';
import { clearSession } from '../../redux/session/SessionAction';
import { Toast } from '../common/Toast';

interface IProps {
  actionCode: string;
  continueUrl?: string;
}

const Content = ({ actionCode, continueUrl }: IProps) => {
  const dispatch = useDispatch();
  const {
    register, handleSubmit, watch, errors, getValues,
  } = useForm<{ password: string; passwordConfirm: string }>();
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const passwordRef = register({
    required: '必須項目です',
    minLength: {
      value: 8,
      message: '8文字以上必要です',
    },
  });

  const passwordConfirmRef = register({
    required: '必須項目です',
    minLength: {
      value: 8,
      message: '8文字以上必要です',
    },
    validate: v => v === watch('password') || 'パスワードと確認用パスワードが一致しません',
  });

  const onSubmit = () => {
    setLoading(true);
    setErrorMessage(null);
    const { password } = getValues();
    firebase.auth().confirmPasswordReset(actionCode, password)
      .then(() => {
        setCompleted(true);
      }).catch((error: firebase.auth.Error) => {
        switch (error.code) {
          case 'auth/expired-action-code':
            setErrorMessage('パスワードリセットメールの有効期限が切れています');
            break;
          case 'auth/invalid-action-code':
            setErrorMessage('すでにパスワードリセット済みか、パスワードリセット用コードが無効です');
            break;
          case 'auth/user-disabled':
            setErrorMessage('ユーザは無効です');
            break;
          case 'auth/user-not-found':
            setErrorMessage('ユーザが見つかりません');
            break;
          default:
            setErrorMessage('パスワードリセットに失敗しました');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const redirect = () => {
    dispatch(clearRedirect());
    dispatch(clearSession());
    window.location.href = continueUrl || '/';
  };

  if (completed) {
    return (
      <Grid
        container
        alignContent="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid
          item
          style={{
            background: 'white',
            borderRadius: '5px',
            width: '500px',
            marginBottom: '10px',
            padding: '20px',
          }}
        >
          <Box m={2}>
            <Box m={1}>
              <Typography variant="h6" component="h3">
                パスワード変更完了
              </Typography>
            </Box>
            <Box m={1}>
              パスワードの変更が完了しました。
            </Box>
            <Box m={1}>
              <Button color="primary" onClick={redirect}>ログインページに戻る</Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      alignContent="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid
        item
        style={{
          background: 'white',
          borderRadius: '5px',
          width: '500px',
          marginBottom: '10px',
          padding: '20px',
        }}
      >
        <Box p={2} m={2}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">

            <Box p={1}>
              <TextField
                type="password"
                name="password"
                label="新しいパスワード"
                fullWidth
                inputRef={passwordRef}
              />
              {
                errors.password && (
                  <FormHelperText error>
                    {errors.password.message}
                  </FormHelperText>
                )
              }
            </Box>
            <Box p={1}>
              <TextField
                type="password"
                name="passwordConfirm"
                label="新しいパスワード(確認用)"
                fullWidth
                inputRef={passwordConfirmRef}
              />
              {
                errors.passwordConfirm && (
                  <FormHelperText error>
                    {errors.passwordConfirm.message}
                  </FormHelperText>
                )
              }
            </Box>
            <Box p={1}>
              <Button
                type="submit"
                color="primary"
                fullWidth
                disabled={loading}
                style={{ lineHeight: '36px' }}
              >
                {loading && <CircularProgress size={20} />}
                パスワード変更
              </Button>
            </Box>
          </form>
        </Box>
      </Grid>
      <Toast
        message={errorMessage}
        duration={3000}
      />
    </Grid>
  );
};

export const ResetPassword = Content;
