// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Employee API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 認証リクエスト
 * @export
 * @interface AccountAuthRequest
 */
export interface AccountAuthRequest {
    /**
     * メールアドレス
     * @type {string}
     * @memberof AccountAuthRequest
     */
    email: string;
    /**
     * Firebase ID token
     * @type {string}
     * @memberof AccountAuthRequest
     */
    idToken: string;
}
/**
 * 認証レスポンス
 * @export
 * @interface AccountAuthResponse
 */
export interface AccountAuthResponse {
    /**
     * ユーザID(Firebaseのものではなくシステム上のID)
     * @type {number}
     * @memberof AccountAuthResponse
     */
    userId: number;
    /**
     * メールアドレス
     * @type {string}
     * @memberof AccountAuthResponse
     */
    email: string;
    /**
     * 氏名
     * @type {string}
     * @memberof AccountAuthResponse
     */
    name: string;
    /**
     * Firebase ID Token
     * @type {string}
     * @memberof AccountAuthResponse
     */
    idToken: string;
}
/**
 * アカウント作成リクエスト
 * @export
 * @interface AccountCreateRequest
 */
export interface AccountCreateRequest {
    /**
     * 名前
     * @type {string}
     * @memberof AccountCreateRequest
     */
    name: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof AccountCreateRequest
     */
    email: string;
    /**
     * パスワード
     * @type {string}
     * @memberof AccountCreateRequest
     */
    password: string;
}
/**
 * アカウント作成レスポンス
 * @export
 * @interface AccountCreateResponse
 */
export interface AccountCreateResponse {
    /**
     * ユーザID(Firebaseのものではなくシステム上のID)
     * @type {number}
     * @memberof AccountCreateResponse
     */
    userId: number;
    /**
     * メールアドレス
     * @type {string}
     * @memberof AccountCreateResponse
     */
    email: string;
    /**
     * 氏名
     * @type {string}
     * @memberof AccountCreateResponse
     */
    name: string;
    /**
     * Firebase Custom Token
     * @type {string}
     * @memberof AccountCreateResponse
     */
    customToken: string;
}
/**
 * 設問回答リクエスト
 * @export
 * @interface AnswerRequest
 */
export interface AnswerRequest {
    /**
     * 設問ID
     * @type {number}
     * @memberof AnswerRequest
     */
    questionId: number;
    /**
     * 選択肢ID
     * @type {number}
     * @memberof AnswerRequest
     */
    choiceId?: number;
    /**
     * 値
     * @type {string}
     * @memberof AnswerRequest
     */
    value?: string;
}
/**
 * 設問回答
 * @export
 * @interface AnswerResponse
 */
export interface AnswerResponse {
    /**
     * 設問回答ID
     * @type {number}
     * @memberof AnswerResponse
     */
    id: number;
    /**
     * アンケート回答ID
     * @type {number}
     * @memberof AnswerResponse
     */
    surveyResultId: number;
    /**
     * 設問ID
     * @type {number}
     * @memberof AnswerResponse
     */
    questionId: number;
    /**
     * 表示名
     * @type {string}
     * @memberof AnswerResponse
     */
    displayName?: string;
    /**
     * 値
     * @type {string}
     * @memberof AnswerResponse
     */
    value: string;
}
/**
 * 設問回答PUTリクエスト
 * @export
 * @interface AnswersPutRequest
 */
export interface AnswersPutRequest {
    /**
     * 設問ID
     * @type {number}
     * @memberof AnswersPutRequest
     */
    questionId: number;
    /**
     * 設問回答
     * @type {Array<AnswersPutRequestAnswers>}
     * @memberof AnswersPutRequest
     */
    answers: Array<AnswersPutRequestAnswers>;
}
/**
 * 
 * @export
 * @interface AnswersPutRequestAnswers
 */
export interface AnswersPutRequestAnswers {
    /**
     * 選択肢ID
     * @type {number}
     * @memberof AnswersPutRequestAnswers
     */
    choiceId?: number;
    /**
     * 値
     * @type {string}
     * @memberof AnswersPutRequestAnswers
     */
    value?: string;
}
/**
 * アンケート設問の選択肢
 * @export
 * @interface ChoiceResponse
 */
export interface ChoiceResponse {
    /**
     * アンケート設問回答ID
     * @type {number}
     * @memberof ChoiceResponse
     */
    id: number;
    /**
     * 設問回答表示名
     * @type {string}
     * @memberof ChoiceResponse
     */
    title: string;
    /**
     * アンケート設問回答値
     * @type {string}
     * @memberof ChoiceResponse
     */
    value: string;
}
/**
 * 面談記録
 * @export
 * @interface CounselingReservationResponse
 */
export interface CounselingReservationResponse {
    /**
     * 面談予約ID
     * @type {number}
     * @memberof CounselingReservationResponse
     */
    counselingReservationId: number;
    /**
     * 従業員ID
     * @type {number}
     * @memberof CounselingReservationResponse
     */
    employeeId?: number;
    /**
     * 従業員コメント
     * @type {string}
     * @memberof CounselingReservationResponse
     */
    employeeComment?: string;
    /**
     * 面談予定日時
     * @type {Date}
     * @memberof CounselingReservationResponse
     */
    reservationTimestamp?: Date;
    /**
     * 従業員緊急連絡先
     * @type {string}
     * @memberof CounselingReservationResponse
     */
    emergencyContact?: string;
    /**
     * 面談実施ステータス
     * @type {string}
     * @memberof CounselingReservationResponse
     */
    status: CounselingReservationResponseStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CounselingReservationResponseStatusEnum {
    Reservation = 'reservation',
    Cancel = 'cancel',
    Counseled = 'counseled'
}

/**
 * エラー
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * エラーコード
     * @type {number}
     * @memberof ErrorResponse
     */
    code: number;
    /**
     * エラーメッセージ
     * @type {string}
     * @memberof ErrorResponse
     */
    message: string;
}
/**
 * アンケート設問
 * @export
 * @interface QestionResponse
 */
export interface QestionResponse {
    /**
     * アンケート設問ID
     * @type {number}
     * @memberof QestionResponse
     */
    id: number;
    /**
     * 設問タイトル
     * @type {string}
     * @memberof QestionResponse
     */
    title: string;
    /**
     * アンケート設問種別
     * @type {string}
     * @memberof QestionResponse
     */
    type: QestionResponseTypeEnum;
    /**
     * 設問回答必須有無
     * @type {boolean}
     * @memberof QestionResponse
     */
    required?: boolean;
    /**
     * アンケート設問回答選択肢
     * @type {Array<ChoiceResponse>}
     * @memberof QestionResponse
     */
    choices?: Array<ChoiceResponse>;
}

/**
    * @export
    * @enum {string}
    */
export enum QestionResponseTypeEnum {
    Radio = 'radio',
    Checkbox = 'checkbox',
    Slider = 'slider',
    Text = 'text'
}

/**
 * スケジュール枠レスポンス
 * @export
 * @interface ScheduleFrameResponse
 */
export interface ScheduleFrameResponse {
    /**
     * ID
     * @type {number}
     * @memberof ScheduleFrameResponse
     */
    id: number;
    /**
     * 対象日
     * @type {string}
     * @memberof ScheduleFrameResponse
     */
    scheduleDate: string;
    /**
     * 枠時間
     * @type {number}
     * @memberof ScheduleFrameResponse
     */
    frameTime: number;
}
/**
 * スケジュール枠一覧レスポンス
 * @export
 * @interface ScheduleFramesResponse
 */
export interface ScheduleFramesResponse {
    /**
     * スケジュール枠
     * @type {Array<ScheduleFrameResponse>}
     * @memberof ScheduleFramesResponse
     */
    scheduleFrames?: Array<ScheduleFrameResponse>;
}
/**
 * アンケート
 * @export
 * @interface SurveyResponse
 */
export interface SurveyResponse {
    /**
     * アンケート配信UUID
     * @type {string}
     * @memberof SurveyResponse
     */
    uuid: string;
    /**
     * アンケートタイトル
     * @type {string}
     * @memberof SurveyResponse
     */
    title: string;
    /**
     * アンケート設問
     * @type {Array<QestionResponse>}
     * @memberof SurveyResponse
     */
    questions: Array<QestionResponse>;
    /**
     * 
     * @type {SurveyResponseSurveyResult}
     * @memberof SurveyResponse
     */
    surveyResult?: SurveyResponseSurveyResult;
}
/**
 * 
 * @export
 * @interface SurveyResponseSurveyResult
 */
export interface SurveyResponseSurveyResult {
    /**
     * アンケート回答ID
     * @type {number}
     * @memberof SurveyResponseSurveyResult
     */
    id: number;
    /**
     * 回答完了済み
     * @type {boolean}
     * @memberof SurveyResponseSurveyResult
     */
    completed: boolean;
}
/**
 * アンケート回答リクエスト
 * @export
 * @interface SurveyResultRequest
 */
export interface SurveyResultRequest {
    /**
     * アンケート配信UUID
     * @type {string}
     * @memberof SurveyResultRequest
     */
    surveyDeliveryUUID: string;
    /**
     * 設問回答
     * @type {Array<AnswerRequest>}
     * @memberof SurveyResultRequest
     */
    answers: Array<AnswerRequest>;
}
/**
 * アンケート回答
 * @export
 * @interface SurveyResultResponse
 */
export interface SurveyResultResponse {
    /**
     * アンケート回答ID
     * @type {number}
     * @memberof SurveyResultResponse
     */
    id: number;
    /**
     * 従業員ID
     * @type {number}
     * @memberof SurveyResultResponse
     */
    employeeId: number;
    /**
     * 設問回答
     * @type {Array<AnswerResponse>}
     * @memberof SurveyResultResponse
     */
    answers: Array<AnswerResponse>;
}
/**
 * 面談予約更新リクエスト
 * @export
 * @interface UpdateCounselingRequest
 */
export interface UpdateCounselingRequest {
    /**
     * 面談予定日時
     * @type {Date}
     * @memberof UpdateCounselingRequest
     */
    reservationTimestamp?: Date;
    /**
     * スケジュール枠id
     * @type {number}
     * @memberof UpdateCounselingRequest
     */
    scheduleFrameId?: number;
    /**
     * 従業員コメント
     * @type {string}
     * @memberof UpdateCounselingRequest
     */
    employeeComment?: string;
    /**
     * 従業員緊急連絡先
     * @type {string}
     * @memberof UpdateCounselingRequest
     */
    emergencyContact: string;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アカウントを認証する
         * @param {AccountAuthRequest} accountAuthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAuthPost(accountAuthRequest: AccountAuthRequest, options: any = {}): RequestArgs {
            // verify required parameter 'accountAuthRequest' is not null or undefined
            if (accountAuthRequest === null || accountAuthRequest === undefined) {
                throw new RequiredError('accountAuthRequest','Required parameter accountAuthRequest was null or undefined when calling accountsAuthPost.');
            }
            const localVarPath = `/accounts/auth`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"AccountAuthRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(accountAuthRequest !== undefined ? accountAuthRequest : {}) : (accountAuthRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アカウントを作成する
         * @param {AccountCreateRequest} accountCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsCreatePost(accountCreateRequest: AccountCreateRequest, options: any = {}): RequestArgs {
            // verify required parameter 'accountCreateRequest' is not null or undefined
            if (accountCreateRequest === null || accountCreateRequest === undefined) {
                throw new RequiredError('accountCreateRequest','Required parameter accountCreateRequest was null or undefined when calling accountsCreatePost.');
            }
            const localVarPath = `/accounts/create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"AccountCreateRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(accountCreateRequest !== undefined ? accountCreateRequest : {}) : (accountCreateRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アカウントが存在するか確認する
         * @param {string} email メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsHead(email: string, options: any = {}): RequestArgs {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling accountsHead.');
            }
            const localVarPath = `/accounts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アカウントを認証する
         * @param {AccountAuthRequest} accountAuthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAuthPost(accountAuthRequest: AccountAuthRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountAuthResponse> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountsAuthPost(accountAuthRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary アカウントを作成する
         * @param {AccountCreateRequest} accountCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsCreatePost(accountCreateRequest: AccountCreateRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountCreateResponse> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountsCreatePost(accountCreateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary アカウントが存在するか確認する
         * @param {string} email メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsHead(email: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountsHead(email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary アカウントを認証する
         * @param {AccountAuthRequest} accountAuthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAuthPost(accountAuthRequest: AccountAuthRequest, options?: any) {
            return AccountApiFp(configuration).accountsAuthPost(accountAuthRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary アカウントを作成する
         * @param {AccountCreateRequest} accountCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsCreatePost(accountCreateRequest: AccountCreateRequest, options?: any) {
            return AccountApiFp(configuration).accountsCreatePost(accountCreateRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary アカウントが存在するか確認する
         * @param {string} email メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsHead(email: string, options?: any) {
            return AccountApiFp(configuration).accountsHead(email, options)(axios, basePath);
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary アカウントを認証する
     * @param {AccountAuthRequest} accountAuthRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountsAuthPost(accountAuthRequest: AccountAuthRequest, options?: any) {
        return AccountApiFp(this.configuration).accountsAuthPost(accountAuthRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary アカウントを作成する
     * @param {AccountCreateRequest} accountCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountsCreatePost(accountCreateRequest: AccountCreateRequest, options?: any) {
        return AccountApiFp(this.configuration).accountsCreatePost(accountCreateRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary アカウントが存在するか確認する
     * @param {string} email メールアドレス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountsHead(email: string, options?: any) {
        return AccountApiFp(this.configuration).accountsHead(email, options)(this.axios, this.basePath);
    }

}


/**
 * CounselingApi - axios parameter creator
 * @export
 */
export const CounselingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 自分の最新の面談記録を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsMyselfGet(options: any = {}): RequestArgs {
            const localVarPath = `/counselings/myself`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 面談記録を更新する
         * @param {number} reservationId 面談予約ID
         * @param {UpdateCounselingRequest} updateCounselingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsReservationIdPut(reservationId: number, updateCounselingRequest: UpdateCounselingRequest, options: any = {}): RequestArgs {
            // verify required parameter 'reservationId' is not null or undefined
            if (reservationId === null || reservationId === undefined) {
                throw new RequiredError('reservationId','Required parameter reservationId was null or undefined when calling counselingsReservationIdPut.');
            }
            // verify required parameter 'updateCounselingRequest' is not null or undefined
            if (updateCounselingRequest === null || updateCounselingRequest === undefined) {
                throw new RequiredError('updateCounselingRequest','Required parameter updateCounselingRequest was null or undefined when calling counselingsReservationIdPut.');
            }
            const localVarPath = `/counselings/{reservation_id}`
                .replace(`{${"reservation_id"}}`, encodeURIComponent(String(reservationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"UpdateCounselingRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateCounselingRequest !== undefined ? updateCounselingRequest : {}) : (updateCounselingRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CounselingApi - functional programming interface
 * @export
 */
export const CounselingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 自分の最新の面談記録を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsMyselfGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CounselingReservationResponse> {
            const localVarAxiosArgs = CounselingApiAxiosParamCreator(configuration).counselingsMyselfGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 面談記録を更新する
         * @param {number} reservationId 面談予約ID
         * @param {UpdateCounselingRequest} updateCounselingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsReservationIdPut(reservationId: number, updateCounselingRequest: UpdateCounselingRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CounselingApiAxiosParamCreator(configuration).counselingsReservationIdPut(reservationId, updateCounselingRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CounselingApi - factory interface
 * @export
 */
export const CounselingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 自分の最新の面談記録を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsMyselfGet(options?: any) {
            return CounselingApiFp(configuration).counselingsMyselfGet(options)(axios, basePath);
        },
        /**
         * 
         * @summary 面談記録を更新する
         * @param {number} reservationId 面談予約ID
         * @param {UpdateCounselingRequest} updateCounselingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsReservationIdPut(reservationId: number, updateCounselingRequest: UpdateCounselingRequest, options?: any) {
            return CounselingApiFp(configuration).counselingsReservationIdPut(reservationId, updateCounselingRequest, options)(axios, basePath);
        },
    };
};

/**
 * CounselingApi - object-oriented interface
 * @export
 * @class CounselingApi
 * @extends {BaseAPI}
 */
export class CounselingApi extends BaseAPI {
    /**
     * 
     * @summary 自分の最新の面談記録を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounselingApi
     */
    public counselingsMyselfGet(options?: any) {
        return CounselingApiFp(this.configuration).counselingsMyselfGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 面談記録を更新する
     * @param {number} reservationId 面談予約ID
     * @param {UpdateCounselingRequest} updateCounselingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounselingApi
     */
    public counselingsReservationIdPut(reservationId: number, updateCounselingRequest: UpdateCounselingRequest, options?: any) {
        return CounselingApiFp(this.configuration).counselingsReservationIdPut(reservationId, updateCounselingRequest, options)(this.axios, this.basePath);
    }

}


/**
 * ScheduleFrameApi - axios parameter creator
 * @export
 */
export const ScheduleFrameApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary スケジュール枠の一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleFramesGet(options: any = {}): RequestArgs {
            const localVarPath = `/schedule-frames`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScheduleFrameApi - functional programming interface
 * @export
 */
export const ScheduleFrameApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary スケジュール枠の一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleFramesGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduleFramesResponse> {
            const localVarAxiosArgs = ScheduleFrameApiAxiosParamCreator(configuration).scheduleFramesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ScheduleFrameApi - factory interface
 * @export
 */
export const ScheduleFrameApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary スケジュール枠の一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleFramesGet(options?: any) {
            return ScheduleFrameApiFp(configuration).scheduleFramesGet(options)(axios, basePath);
        },
    };
};

/**
 * ScheduleFrameApi - object-oriented interface
 * @export
 * @class ScheduleFrameApi
 * @extends {BaseAPI}
 */
export class ScheduleFrameApi extends BaseAPI {
    /**
     * 
     * @summary スケジュール枠の一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleFrameApi
     */
    public scheduleFramesGet(options?: any) {
        return ScheduleFrameApiFp(this.configuration).scheduleFramesGet(options)(this.axios, this.basePath);
    }

}


/**
 * SurveyApi - axios parameter creator
 * @export
 */
export const SurveyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アンケートを取得する
         * @param {string} uuid アンケート配信UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysUuidGet(uuid: string, options: any = {}): RequestArgs {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling surveysUuidGet.');
            }
            const localVarPath = `/surveys/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SurveyApi - functional programming interface
 * @export
 */
export const SurveyApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アンケートを取得する
         * @param {string} uuid アンケート配信UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysUuidGet(uuid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyResponse> {
            const localVarAxiosArgs = SurveyApiAxiosParamCreator(configuration).surveysUuidGet(uuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SurveyApi - factory interface
 * @export
 */
export const SurveyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary アンケートを取得する
         * @param {string} uuid アンケート配信UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveysUuidGet(uuid: string, options?: any) {
            return SurveyApiFp(configuration).surveysUuidGet(uuid, options)(axios, basePath);
        },
    };
};

/**
 * SurveyApi - object-oriented interface
 * @export
 * @class SurveyApi
 * @extends {BaseAPI}
 */
export class SurveyApi extends BaseAPI {
    /**
     * 
     * @summary アンケートを取得する
     * @param {string} uuid アンケート配信UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyApi
     */
    public surveysUuidGet(uuid: string, options?: any) {
        return SurveyApiFp(this.configuration).surveysUuidGet(uuid, options)(this.axios, this.basePath);
    }

}


/**
 * SurveyResultApi - axios parameter creator
 * @export
 */
export const SurveyResultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 設問回答を更新する
         * @param {number} id アンケート回答ID
         * @param {AnswersPutRequest} answersPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResultsIdAnswersPut(id: number, answersPutRequest: AnswersPutRequest, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyResultsIdAnswersPut.');
            }
            // verify required parameter 'answersPutRequest' is not null or undefined
            if (answersPutRequest === null || answersPutRequest === undefined) {
                throw new RequiredError('answersPutRequest','Required parameter answersPutRequest was null or undefined when calling surveyResultsIdAnswersPut.');
            }
            const localVarPath = `/survey-results/{id}/answers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"AnswersPutRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(answersPutRequest !== undefined ? answersPutRequest : {}) : (answersPutRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アンケート回答を更新する
         * @param {number} id 設問回答ID
         * @param {SurveyResultRequest} surveyResultRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResultsIdPut(id: number, surveyResultRequest: SurveyResultRequest, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyResultsIdPut.');
            }
            // verify required parameter 'surveyResultRequest' is not null or undefined
            if (surveyResultRequest === null || surveyResultRequest === undefined) {
                throw new RequiredError('surveyResultRequest','Required parameter surveyResultRequest was null or undefined when calling surveyResultsIdPut.');
            }
            const localVarPath = `/survey-results/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"SurveyResultRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(surveyResultRequest !== undefined ? surveyResultRequest : {}) : (surveyResultRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アンケート回答を登録する
         * @param {SurveyResultRequest} surveyResultRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResultsPost(surveyResultRequest: SurveyResultRequest, options: any = {}): RequestArgs {
            // verify required parameter 'surveyResultRequest' is not null or undefined
            if (surveyResultRequest === null || surveyResultRequest === undefined) {
                throw new RequiredError('surveyResultRequest','Required parameter surveyResultRequest was null or undefined when calling surveyResultsPost.');
            }
            const localVarPath = `/survey-results`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"SurveyResultRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(surveyResultRequest !== undefined ? surveyResultRequest : {}) : (surveyResultRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SurveyResultApi - functional programming interface
 * @export
 */
export const SurveyResultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 設問回答を更新する
         * @param {number} id アンケート回答ID
         * @param {AnswersPutRequest} answersPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResultsIdAnswersPut(id: number, answersPutRequest: AnswersPutRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SurveyResultApiAxiosParamCreator(configuration).surveyResultsIdAnswersPut(id, answersPutRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary アンケート回答を更新する
         * @param {number} id 設問回答ID
         * @param {SurveyResultRequest} surveyResultRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResultsIdPut(id: number, surveyResultRequest: SurveyResultRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = SurveyResultApiAxiosParamCreator(configuration).surveyResultsIdPut(id, surveyResultRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary アンケート回答を登録する
         * @param {SurveyResultRequest} surveyResultRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResultsPost(surveyResultRequest: SurveyResultRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyResultResponse> {
            const localVarAxiosArgs = SurveyResultApiAxiosParamCreator(configuration).surveyResultsPost(surveyResultRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SurveyResultApi - factory interface
 * @export
 */
export const SurveyResultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 設問回答を更新する
         * @param {number} id アンケート回答ID
         * @param {AnswersPutRequest} answersPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResultsIdAnswersPut(id: number, answersPutRequest: AnswersPutRequest, options?: any) {
            return SurveyResultApiFp(configuration).surveyResultsIdAnswersPut(id, answersPutRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary アンケート回答を更新する
         * @param {number} id 設問回答ID
         * @param {SurveyResultRequest} surveyResultRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResultsIdPut(id: number, surveyResultRequest: SurveyResultRequest, options?: any) {
            return SurveyResultApiFp(configuration).surveyResultsIdPut(id, surveyResultRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary アンケート回答を登録する
         * @param {SurveyResultRequest} surveyResultRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyResultsPost(surveyResultRequest: SurveyResultRequest, options?: any) {
            return SurveyResultApiFp(configuration).surveyResultsPost(surveyResultRequest, options)(axios, basePath);
        },
    };
};

/**
 * SurveyResultApi - object-oriented interface
 * @export
 * @class SurveyResultApi
 * @extends {BaseAPI}
 */
export class SurveyResultApi extends BaseAPI {
    /**
     * 
     * @summary 設問回答を更新する
     * @param {number} id アンケート回答ID
     * @param {AnswersPutRequest} answersPutRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyResultApi
     */
    public surveyResultsIdAnswersPut(id: number, answersPutRequest: AnswersPutRequest, options?: any) {
        return SurveyResultApiFp(this.configuration).surveyResultsIdAnswersPut(id, answersPutRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary アンケート回答を更新する
     * @param {number} id 設問回答ID
     * @param {SurveyResultRequest} surveyResultRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyResultApi
     */
    public surveyResultsIdPut(id: number, surveyResultRequest: SurveyResultRequest, options?: any) {
        return SurveyResultApiFp(this.configuration).surveyResultsIdPut(id, surveyResultRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary アンケート回答を登録する
     * @param {SurveyResultRequest} surveyResultRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyResultApi
     */
    public surveyResultsPost(surveyResultRequest: SurveyResultRequest, options?: any) {
        return SurveyResultApiFp(this.configuration).surveyResultsPost(surveyResultRequest, options)(this.axios, this.basePath);
    }

}


