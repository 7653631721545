import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box, Grid, TextField, FormHelperText, Button, CircularProgress, Link,
} from '@material-ui/core';
import useForm from 'react-hook-form';
import firebase from 'firebase';
import { useDispatch, useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { SessionState } from '../../redux/session/SessionState';
import { updateSession } from '../../redux/session/SessionAction';
import { AccountAuthRequest, AccountApi } from '../../generated';
import { usePublicApi } from '../../api/useApi';
import { redirectSelector } from '../../foundation/Store';
import { clearRedirect } from '../../redux/redirect/RedirectAction';
import { Toast } from '../../components/common/Toast';
import { MainLayout } from '../../layouts';
import { Logo } from '../../components/common/Logo';

const isEmail = (email: string) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const Content = () => {
  const {
    register, handleSubmit, getValues, errors,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const dispatch = useDispatch();
  const { history } = useReactRouter();
  const redirect = useSelector(redirectSelector);
  const { api } = usePublicApi(AccountApi);

  const emailRef = register({
    required: '必須項目です',
    validate: v => isEmail(v) || '正しいEメールアドレスではありません',
  });

  const passwordRef = register({
    required: '必須項目です',
    minLength: {
      value: 8,
      message: '8文字以上必要です',
    },
  });

  const login = async () => {
    setLoading(true);
    setMessage(null);
    const { email, password } = getValues();
    if (typeof email !== 'string' || typeof password !== 'string') {
      throw new Error('email or password is undefined');
    }

    try {
      const firebaseResponse = await firebase.auth()
        .signInWithEmailAndPassword(email, password);
      const { user } = firebaseResponse;
      if (!user) {
        throw new Error('user is not found');
      }

      const idToken = await user.getIdToken();

      const request: AccountAuthRequest = {
        email,
        idToken,
      };
      const response = await api.accountsAuthPost(request);
      const account = response.data;

      const session: SessionState = {
        principal: { name: account.name, id: String(account.userId) },
        isVerified: user.emailVerified,
        token: idToken,
        expireAt: new Date(), // TODO: set expiration time
      };
      dispatch(updateSession(session));
      history.push(redirect);
      dispatch(clearRedirect());
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setMessage('このメールアドレスはご利用いただけません。\n組織で割り当てられているメールアドレスをご利用ください。');
      } else {
        setMessage('ログインできませんでした。\nIDまたはパスワードをご確認ください。');
      }
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = () => {
    login();
  };

  return (
    <Grid
      container
      alignContent="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid
        item
        style={{
          background: 'white',
          borderRadius: '5px',
          width: '500px',
          marginBottom: '10px',
          padding: '20px',
        }}
      >
        <Box p={2} m={2}>
          <Box style={{ width: '100%', textAlign: 'center' }}>
            <Logo />
          </Box>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Box p={1}>
              <TextField
                type="text"
                name="email"
                label="Email"
                fullWidth
                inputRef={emailRef}
              />
              {
                errors.email && (
                  <FormHelperText error>
                    {errors.email.message}
                  </FormHelperText>
                )
              }
            </Box>
            <Box p={1}>
              <TextField
                type="password"
                name="password"
                label="Password"
                fullWidth
                inputRef={passwordRef}
              />
              {
                errors.password && (
                  <FormHelperText error>
                    {errors.password.message}
                  </FormHelperText>
                )
              }
            </Box>
            <Box p={1}>
              <Button
                type="submit"
                color="primary"
                fullWidth
                disabled={loading}
                style={{ lineHeight: '36px' }}
              >
                {loading && <CircularProgress size={20} />}
                ログイン
              </Button>
            </Box>
            <Box style={{ textAlign: 'center' }}>
              <Box m={1}>
                <Link to="/auth/register" component={RouterLink}>新規アカウントを登録する</Link>
              </Box>
              <Box m={1}>
                <Link to="/auth/password-reset" component={RouterLink}>パスワードを忘れた方はこちら</Link>
              </Box>
            </Box>
          </form>
        </Box>

      </Grid>
      <Toast
        message={message}
        duration={3000}
      />
    </Grid>
  );
};

export const LoginPage = MainLayout(Content);
