import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box, Grid, TextField, FormHelperText, Button, CircularProgress, Checkbox, Link,
} from '@material-ui/core';
import useForm from 'react-hook-form';
import firebase from 'firebase';
import { useDispatch, useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { AccountCreateRequest, AccountApi } from '../../generated';
import { SessionState } from '../../redux/session/SessionState';
import { updateSession } from '../../redux/session/SessionAction';
import { usePublicApi } from '../../api/useApi';
import { Toast } from '../../components/common/Toast';
import { Logo } from '../../components/common/Logo';
import { redirectSelector } from '../../foundation/Store';


// TODO: 一箇所にまとめる
const isEmail = (email: string) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const Content = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {
    register, handleSubmit, errors, getValues, watch,
  } = useForm();
  const { api } = usePublicApi(AccountApi);
  const dispatch = useDispatch();
  const { history } = useReactRouter();
  const redirectUrl = useSelector(redirectSelector);

  const nameRef = register({
    required: '必須項目です',
  });

  const emailRef = register({
    required: '必須項目です',
    validate: v => isEmail(v) || '正しいEメールアドレスではありません',
  });

  const passwordRef = register({
    required: '必須項目です',
    minLength: {
      value: 8,
      message: '8文字以上必要です',
    },
  });

  const passwordConfirmRef = register({
    required: '必須項目です',
    minLength: {
      value: 8,
      message: '8文字以上必要です',
    },
    validate: v => v === watch('password') || 'パスワードと確認用パスワードが一致しません',
  });

  const agreementRef = register({
    required: '利用規約に同意する必要があります',
  });

  const createAcount = async () => {
    setLoading(true);
    setErrorMessage(null);
    const { name, email, password } = getValues();
    if (typeof name !== 'string' || typeof email !== 'string' || typeof password !== 'string') {
      throw new Error('Unexpected Type: email or password is incorrect.');
    }

    const request: AccountCreateRequest = {
      name,
      email,
      password,
    };
    try {
      const response = await api.accountsCreatePost(request);
      if (response && response.data) {
        const account = response.data;
        const firebaseResponse = await firebase.auth().signInWithCustomToken(account.customToken);
        const { user } = firebaseResponse;
        if (!user) {
          throw new Error('user is not found');
        }
        const token = await user.getIdToken();
        await user.sendEmailVerification({ url: window.location.origin + redirectUrl });

        const session: SessionState = {
          principal: { name, id: String(account.userId) },
          isVerified: user.emailVerified,
          token,
          expireAt: new Date(), // TODO: set expiration time
        };
        // firebaseから取得したトークンとUserIDをバックエンドのAPIに投げてアカウントを作成
        dispatch(updateSession(session));
        history.push('/auth/not-verified');
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setErrorMessage('このメールアドレスはご利用いただけません。\n組織で割り当てられているメールアドレスをご利用ください。');
      } else {
        setErrorMessage('ログインできませんでした。\nIDまたはパスワードをご確認ください。');
      }
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async () => {
    createAcount();
  };

  return (
    <Grid
      container
      spacing={2}
      alignContent="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid
        item
        style={{
          background: 'white',
          borderRadius: '5px',
          width: '500px',
          marginBottom: '10px',
          padding: '20px',
        }}
      >

        <Box p={2}>
          <Box style={{ width: '100%', textAlign: 'center' }}>
            <Logo />
          </Box>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Box p={1}>
              <TextField
                type="text"
                name="name"
                label="お名前"
                fullWidth
                inputRef={nameRef}
              />
              {
                errors.name && (
                  <FormHelperText error>
                    {errors.name.message}
                  </FormHelperText>
                )
              }
            </Box>
            <Box p={1}>
              <TextField
                type="text"
                name="email"
                label="Email"
                fullWidth
                inputRef={emailRef}
              />
              {
                errors.email && (
                  <FormHelperText error>
                    {errors.email.message}
                  </FormHelperText>
                )
              }
            </Box>
            <Box p={1}>
              <TextField
                type="password"
                name="password"
                label="パスワード"
                fullWidth
                inputRef={passwordRef}
              />
              {
                errors.password && (
                  <FormHelperText error>
                    {errors.password.message}
                  </FormHelperText>
                )
              }
            </Box>
            <Box p={1}>
              <TextField
                type="password"
                name="passwordConfirm"
                label="パスワード(確認用)"
                fullWidth
                inputRef={passwordConfirmRef}
              />
              {
                errors.passwordConfirm && (
                  <FormHelperText error>
                    {errors.passwordConfirm.message}
                  </FormHelperText>
                )
              }
            </Box>
            <Box p={1}>
              <Checkbox name="agreement" color="primary" inputRef={agreementRef} />
              <Link to="/about/terms" target="_blank" component={RouterLink}>利用規約</Link>
              に同意する
              {
                errors.agreement && (
                  <FormHelperText error>
                    {errors.agreement.message}
                  </FormHelperText>
                )
              }
            </Box>
            <Box p={1}>
              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                color="primary"
                fullWidth
                style={{ lineHeight: '36px' }}
              >
                {loading && <CircularProgress size={20} />}
                アカウント新規作成
              </Button>
            </Box>
            <Box p={1} style={{ textAlign: 'center' }}>
              アカウントをお持ちの方は
              <Link to="/auth/signin" component={RouterLink}>ログイン</Link>
              から
            </Box>
          </form>
        </Box>
      </Grid>

      <Toast
        message={errorMessage}
        duration={3000}
      />
    </Grid>
  );
};

export const CreateAccountPage = Content;
