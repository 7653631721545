import React from 'react';
import { Button, Grid } from '@material-ui/core';
import useReactRouter from 'use-react-router';
import { AuthedComponentProps, withAuth } from '../auth/Authenticated';
import { MainLayout } from '../layouts';

const Content = ({ session }: AuthedComponentProps) => {
  const { history } = useReactRouter();

  const logout = () => {
    history.push('/auth/signout');
  };

  return (
    <Grid
      container
      spacing={2}
      alignContent="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid
        item
        style={{
          background: 'white',
          padding: '20px',
          paddingTop: '10px',
          borderRadius: '5px',
          width: '500px',
        }}
      >
        <p>{`${session.principal.name} 様 でログイン中です。`}</p>
        <p>アンケートに回答する場合は、メールに添付の回答用URLを開いてください。</p>
        <Button color="primary" onClick={logout}>ログアウト</Button>
      </Grid>
    </Grid>
  );
};

export const TopPage = MainLayout(withAuth(Content));
