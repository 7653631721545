import { createMuiTheme } from '@material-ui/core/styles';

const palette = {
  primary: {
    main: '#0CC7F1',
    dark: '#109DCE',
  },
  secondary: {
    main: '#FF671E',
  },
  background: {
    default: '#E5EDF1',
  },
};

export const theme = createMuiTheme({
  palette,
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      containedPrimary: {
        color: 'white',
        fontWeight: 'bold',
      },
      containedSecondary: {
        color: 'white',
        fontWeight: 'bold',
      },
    },
    MuiLink: {
      root: {
        color: palette.primary.dark,
      },
    },
  },
  props: {
    MuiButton: {
      variant: 'contained',
    },
    MuiTextField: {
      variant: 'outlined',
    },
    MuiLink: {
      underline: 'hover',
    },
  },
});
