import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl, FormLabel, makeStyles, TextField, Theme, createStyles, Box,
} from '@material-ui/core';
import useForm from 'react-hook-form';
import { AnswerState } from '../redux/survey_result/SurveyResultState';
import { updateAnswer, createAnswer } from '../redux/survey_result/SurveyResultAction';
import { surveyResultSelector } from '../foundation/Store';

export interface TextFieldQuestion {
  id: number;
  title: string;
  type: 'text';
  required: boolean;
}

interface IProps {
  question: TextFieldQuestion;
  index: number;
  formMethods: ReturnType<typeof useForm>;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(3),
  },
}));

const Content = ({ question, index, formMethods }: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, getValues, setValue } = formMethods;
  const answer: AnswerState | undefined = useSelector(surveyResultSelector).answers
    .find(a => a.questionId === question.id);

  // TODO: yuki-furukawa@m3.com サーバー上で任意項目が判定出来ないので、一旦空で送信する
  useEffect(() => {
    if (index === 13 && answer == null) {
      dispatch(createAnswer({ questionId: question.id, value: '' }));
    }
    setValue(`q${index + 1}`, answer && answer.value);
  }, [question.id]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const state: AnswerState = {
      questionId: question.id,
      value: event.target.value,
    };
    if (answer == null) {
      dispatch(createAnswer(state));
    } else {
      dispatch(updateAnswer(state));
    }
  }

  return (
    <FormControl className={classes.root}>
      <Box whiteSpace="pre-line">
        <FormLabel>
          {`Q${index + 1}: ${question.title}`}
        </FormLabel>
      </Box>
      <TextField
        name={`q${index + 1}`}
        multiline
        className={classes.textField}
        variant="outlined"
        rows="4"
        value={getValues()[`q${index + 1}`]}
        placeholder="回答を入力"
        onChange={handleChange}
        // TODO: yuki-furukawa@m3.com バリデーションが設定できるようになったら修正する
        inputRef={question.required && index !== 13 ? register({ required: '※回答を入力してください' }) : register}
      />
    </FormControl>
  );
};


export const TextFieldQuestionForm = Content;
