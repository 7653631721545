import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, CircularProgress, createStyles, makeStyles, Theme, Typography, Grid, Box,
} from '@material-ui/core';
import useForm from 'react-hook-form';
import useReactRouter from 'use-react-router';
import { QuestionForm } from '../components/QuestionForm';
import { createSurveyResult, setSurveyResultId, clearSurveyResult } from '../redux/survey_result/SurveyResultAction';
import { SurveyResultState } from '../redux/survey_result/SurveyResultState';
import { surveyResultSelector } from '../foundation/Store';
import {
  SurveyResultApi,
  SurveyApi,
  SurveyResponse,
  AnswersPutRequest,
} from '../generated';
import { NotFoundPage } from './NotFoundPage';
import { usePrivateApi } from '../api/useApi';
import { Toast } from '../components/common/Toast';


const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(6),
      marginRight: theme.spacing(6),
    },
  },
  container: {
    marginTop: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(5),
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 'auto',
    marginBottom: theme.spacing(20),
    width: '100%',
  },
  next: {
    width: '100%',
    height: '50px',
    marginBottom: '10px',
  },
  back: {
    width: '100%',
    height: '50px',
  },
}));

const Content = () => {
  const classes = useStyles();
  const formMethods = useForm();
  const { history, match } = useReactRouter<{ uuid: string }>();
  const { handleSubmit, errors } = formMethods;
  const dispatch = useDispatch();
  const { uuid } = match.params;
  const surveyResult = useSelector(surveyResultSelector);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [index, setIndex] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [survey, setSurvey] = useState<SurveyResponse | null>(null);
  const {
    api: surveyResultApi,
    errorMessage: surveyResultErrorMessage,
  } = usePrivateApi(SurveyResultApi);
  const { api: surveyApi } = usePrivateApi(SurveyApi);

  useEffect(() => {
    setLoading(true);
    surveyApi.surveysUuidGet(uuid).then(res => {
      const surveyData: SurveyResponse = res.data;
      if (surveyData.surveyResult && surveyData.surveyResult.completed) {
        return setCompleted(true);
      }
      setSurvey(surveyData);
      const state: SurveyResultState = {
        id: (surveyData.surveyResult && surveyData.surveyResult.id) || undefined,
        surveyDeliveryUUID: uuid,
        answers: [],
      };
      dispatch(clearSurveyResult());
      dispatch(createSurveyResult(state));
      return true;
    }).catch(_ => {
      setSurvey(null);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  function goToNextOrComplete() {
    const questions = survey && survey.questions;
    if (questions && index < questions.length - 1) {
      setIndex(index + 1);
      window.scrollTo(0, 0);
    } else {
      history.push('/surveys/complete');
    }
  }

  function onSubmit() {
    const questions = survey && survey.questions;
    if (submitting || !survey || !questions) {
      return;
    }
    setSubmitting(true);

    if (surveyResult.id) {
      const questionId = questions[index].id;
      const answers = surveyResult.answers.filter(a => a.questionId === questionId)
        .map(a => ({ choiceId: a.choiceId, value: a.value }));
      const request: AnswersPutRequest = { questionId, answers };
      surveyResultApi.surveyResultsIdAnswersPut(surveyResult.id, request)
        .then(() => {
          setSubmitError(false);
          goToNextOrComplete();
        })
        .catch(() => setSubmitError(true))
        .finally(() => setSubmitting(false));
    } else {
      surveyResultApi.surveyResultsPost(surveyResult)
        .then(res => {
          if (res.status === 201) {
            dispatch(setSurveyResultId(res.data.id));
            setSubmitError(false);
            goToNextOrComplete();
          }
        })
        .catch(() => setSubmitError(true))
        .finally(() => setSubmitting(false));
    }
  }

  function back() {
    if (index > 0) {
      setIndex(index - 1);
    }
  }

  const errorText = () => {
    const uniqueErrors: [string, any][] = [];
    Object.entries(errors).forEach(([key, value]) => {
      if (value == null) return;
      if (!uniqueErrors.map(e => e[1].message).includes(value.message)) {
        uniqueErrors.push([key, value]);
      }
    });
    return uniqueErrors.map(([key, value]) => <Typography color="error" key={key}>{value && value.message}</Typography>);
  };

  if (completed) {
    return <p>このアンケートは既に回答済みです。</p>;
  }

  if (survey && survey.questions) {
    const questions = survey.questions.map(q => {
      const choices = (q.choices || []).map(c => ({
        id: c.id,
        title: c.title,
        value: c.value,
      }));

      return {
        id: q.id,
        title: q.title,
        type: q.type as 'radio' | 'text' | 'checkbox',
        required: q.required || true,
        choices,
      };
    });

    return (
      <Grid
        container
        spacing={2}
        alignContent="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid
          item
          style={{
            background: 'white',
            width: '100%',
          }}
        >
          <div className={classes.root}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className={classes.container}>
                <h2>{survey && survey.title}</h2>
                <QuestionForm
                  question={questions[index]}
                  index={index}
                  key={questions[index].id}
                  formMethods={formMethods}
                />
              </div>
              <div className={classes.footer}>
                <Box justifyContent="center">
                  <Grid
                    container
                    direction="row-reverse"
                    justify="space-between"
                  >
                    <Grid xs={12} sm={2} />
                    <Grid xs={12} sm={2}>
                      <Button className={classes.next} variant="contained" color="primary" type="submit">
                        次へ進む
                        {submitting && <CircularProgress color="inherit" />}
                      </Button>
                    </Grid>
                    <Grid xs={12} sm={2}>
                      <Box>
                        {index > 0 ? <Button className={classes.back} variant="contained" onClick={back}>前に戻る</Button> : <></>}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Grid container justify="center">
                  {errors
                    ? errorText()
                    : <></>
                  }
                  {submitError && <>送信時にエラーが発生しました。もう一度送信してください。</>}
                </Grid>
              </div>
            </form>
            <Toast
              message={surveyResultErrorMessage}
              duration={3000}
            />
          </div>
        </Grid>
      </Grid>
    );
  } if (survey === null && !loading) {
    return <NotFoundPage />;
  }
  return <></>;
};

export const SurveyPage = Content;
