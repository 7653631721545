import React from 'react';
import useForm from 'react-hook-form';
import { RadioQuestion, RadioQuestionForm } from './RadioQuestionForm';
import { TextFieldQuestion, TextFieldQuestionForm } from './TextFieldQuestionForm';
import { CheckBoxQuestion, CheckBoxQuestionForm } from './CheckBoxQuestionForm';
import { SliderQuestion, SliderQuestionForm } from './SliderQuestionForm';


export type Question = RadioQuestion | CheckBoxQuestion | TextFieldQuestion | SliderQuestion;

interface IProps {
  question: Question;
  index: number;
  formMethods: ReturnType<typeof useForm>;
}

const Content = (props: IProps) => {
  switch (props.question.type) {
    case 'radio':
      return <RadioQuestionForm {...{ ...props, question: props.question }} />;
    case 'checkbox':
      return <CheckBoxQuestionForm {...{ ...props, question: props.question }} />;
    case 'slider':
      return <SliderQuestionForm {...{ ...props, question: props.question }} />;
    case 'text':
      return <TextFieldQuestionForm {...{ ...props, question: props.question }} />;
    default:
      return null;
  }
};

export const QuestionForm = Content;
