import { SurveyResultState, init, AnswerState } from './SurveyResultState';
import { Actions, SurveyResultAction, AnswerAction } from './SurveyResultAction';

const AnswersReducer = (state: AnswerState[], action: AnswerAction): AnswerState[] => {
  switch (action.type) {
    case Actions.CREATE_ANSWER: {
      return state.concat(action.payload);
    }
    case Actions.UPDATE_ANSWER: {
      return state.map(item => {
        if (item.questionId === action.payload.questionId) {
          return action.payload;
        }
        return item;
      });
    }
    case Actions.DELETE_ANSWER: {
      return state.filter(s => (
        !(s.questionId === action.payload.questionId && s.choiceId === action.payload.choiceId)
      ));
    }
    default:
      return state;
  }
};

export const SurveyResultReducer = (
  state: SurveyResultState = init(),
  action: SurveyResultAction,
): SurveyResultState => {
  switch (action.type) {
    case Actions.CREATE_SURVEY_RESULT: {
      const actionState: SurveyResultState = action.payload as SurveyResultState;
      return Object.assign({}, state, {
        id: actionState.id,
        surveyDeliveryUUID: actionState.surveyDeliveryUUID,
      });
    }
    case Actions.SET_SURVEY_RESULT_ID: {
      const id: number = action.payload as number;
      return Object.assign({}, state, {
        id,
      });
    }
    case Actions.CLEAR_SURVEY_RESULT: {
      return init();
    }
    case Actions.CREATE_ANSWER:
    case Actions.UPDATE_ANSWER:
    case Actions.DELETE_ANSWER:
      return Object.assign({}, state, {
        answers: AnswersReducer(state.answers, action as AnswerAction),
      });
    default:
      return state;
  }
};
