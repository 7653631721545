import React from 'react';
import { Box, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => createStyles({
  root: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    margin: '0',
  },
  message: {
    margin: theme.spacing(3),
  },
}));

const Content = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <h2 className={classes.message}>アンケートへの回答ありがとうございました。</h2>
    </Box>
  );
};

export const SurveyCompletePage = Content;
