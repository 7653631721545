import actionCreatorFactory from 'typescript-fsa';
import { SurveyResultState, AnswerState } from './SurveyResultState';

export const CREATE_SURVEY_RESULT = 'CREATE_SURVEY_RESULT';
export const SET_SURVEY_RESULT_ID = 'SET_SURVEY_RESULT_ID';
export const CLEAR_SURVEY_RESULT = 'CLEAR_SURVEY_RESULT';
export const CREATE_ANSWER = 'CREATE_ANSWER';
export const UPDATE_ANSWER = 'UPDATE_ANSWER';
export const DELETE_ANSWER = 'DELETE_ANSWER';

export const Actions = {
  CREATE_SURVEY_RESULT,
  SET_SURVEY_RESULT_ID,
  CLEAR_SURVEY_RESULT,
  CREATE_ANSWER,
  UPDATE_ANSWER,
  DELETE_ANSWER,
};

const actionCreator = actionCreatorFactory();
export const createSurveyResult = actionCreator<SurveyResultState>(CREATE_SURVEY_RESULT);
export const setSurveyResultId = actionCreator<number>(SET_SURVEY_RESULT_ID);
export const clearSurveyResult = actionCreator(CLEAR_SURVEY_RESULT);
export const createAnswer = actionCreator<AnswerState>(CREATE_ANSWER);
export const updateAnswer = actionCreator<AnswerState>(UPDATE_ANSWER);
export const deleteAnswer = actionCreator<AnswerState>(DELETE_ANSWER);

export type CreateSurveyResultAction = ReturnType<typeof createSurveyResult>;
export type SetSurveyResultIdAction = ReturnType<typeof setSurveyResultId>;
export type ClearSurveyResultAction = ReturnType<typeof clearSurveyResult>;
export type CreateAnswerAction = ReturnType<typeof createAnswer>;
export type UpdateAnswerAction = ReturnType<typeof updateAnswer>;
export type DeleteAnswerAction = ReturnType<typeof deleteAnswer>;

export type AnswerAction =
  | CreateAnswerAction
  | UpdateAnswerAction
  | DeleteAnswerAction;
export type SurveyResultAction =
  | CreateSurveyResultAction
  | SetSurveyResultIdAction
  | ClearSurveyResultAction
  | AnswerAction;
