import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  FormControl,
  Theme,
  makeStyles,
  createStyles,
  Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import useForm from 'react-hook-form';
import { AnswerState } from '../redux/survey_result/SurveyResultState';
import { surveyResultSelector } from '../foundation/Store';
import { createAnswer, deleteAnswer } from '../redux/survey_result/SurveyResultAction';
import { ChoiceResponse } from '../generated';

export interface CheckBoxQuestion {
  id: number;
  title: string;
  type: 'checkbox';
  choices: Array<ChoiceResponse>;
}

interface IProps {
  question: CheckBoxQuestion;
  index: number;
  formMethods: ReturnType<typeof useForm>;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  checkBox: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
  label: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
}));

const Content = ({ question, index, formMethods }: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, getValues } = formMethods;
  const questionName = `q${index + 1}`;
  const answers: AnswerState[] = useSelector(surveyResultSelector).answers
    .filter(a => a.questionId === question.id);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const state: AnswerState = {
      questionId: question.id,
      choiceId: Number(event.target.value),
    };
    const answer = answers.find(a => a.choiceId === state.choiceId);

    if (answer == null && event.target.checked) {
      dispatch(createAnswer(state));
    }
    if (answer != null && !event.target.checked) {
      dispatch(deleteAnswer(state));
    }
  }

  // TODO: yuki-furukawa@m3.com バリデーションを直接書いているので、アンケートのテンプレートを自由にする場合は修正する
  const checkBoxRef = question.choices.length === 1 ? register({ required: '※同意する必要があります' }) : register({
    validate: () => {
      const count = Object.entries(getValues())
        .map(([_key, value]) => value)
        .filter(a => a !== false)
        .length;
      return (count >= 1 && count <= 3) || '1〜3つ選択してください';
    },
  });

  return (
    <FormControl className={classes.root}>
      <Box whiteSpace="pre-line">
        <FormLabel>
          {`Q${index + 1}: ${question.title}`}
        </FormLabel>
      </Box>
      <FormGroup row className={classes.checkBox} onChange={handleChange}>
        {
          question.choices.map((choice: ChoiceResponse, choiceIndex: number) => (
            <FormControlLabel
              name={`${questionName}[${choiceIndex}]`}
              control={<Checkbox value={choice.id} color="primary" checked={answers.some(item => item.choiceId === choice.id)} />}
              label={choice.title}
              inputRef={checkBoxRef}
              className={classes.label}
            />
          ))
        }
      </FormGroup>
    </FormControl>
  );
};


export const CheckBoxQuestionForm = Content;
