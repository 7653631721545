export interface SurveyResultState {
  id?: number;
  surveyDeliveryUUID: string;
  answers: AnswerState[];
}

export interface AnswerState {
  questionId: number;
  choiceId?: number;
  value?: string;
}

export const init: () => SurveyResultState = () => ({
  surveyDeliveryUUID: '',
  answers: [],
});
