import 'react-app-polyfill/ie9';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import firebase from 'firebase';
import * as Sentry from '@sentry/browser';
import { CssBaseline } from '@material-ui/core';
import { SurveyPage } from './pages/SurveyPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { SurveyCompletePage } from './pages/SurveyCompletePage';
import { CreateAccountPage } from './pages/auth/CreateAccountPage';
import { config } from './auth/FirebaseConfig';
import { LoginPage } from './pages/auth/LoginPage';
import { withAuth } from './auth/Authenticated';
import { AppProvider } from './foundation/AppProvider';
import { NotVerifiedPage } from './pages/NotVerifiedPage';
import { TopPage } from './pages/TopPage';
import { LogoutPage } from './pages/auth/LogoutPage';
import { TermsPage } from './pages/TermsPage';
import { theme } from './theme';
import { PasswordResetPage } from './pages/auth/PasswordResetPage';
import { AccountActionPage } from './pages/auth/AccountActionPage';
import { CounselingReservationPage } from './pages/CounselingReservationPage';
import { CounselingReservationCompletePage } from './pages/CounselingReservationCompletePage';

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
Sentry.configureScope(scope => {
  scope.setTag('module', 'employee');
  scope.setUser({ id: 'guest' });
});
firebase.initializeApp(config);

// TODO: Google翻訳で発生するバグ回避。将来reactのversion upで解決する可能性あり。
const appRoot = document.getElementById('app')!;
appRoot.setAttribute('class', 'notranslate');

ReactDOM.render(
  <AppProvider>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Switch>
          <Route exact path="/" component={withAuth(TopPage)} />
          <Route exact path="/auth/signin" component={LoginPage} />
          <Route exact path="/auth/signout" component={LogoutPage} />
          <Route exact path="/auth/register" component={CreateAccountPage} />
          <Route exact path="/auth/password-reset" component={PasswordResetPage} />
          <Route exact path="/auth/action" component={AccountActionPage} />
          <Route exact path="/auth/not-verified" component={withAuth(NotVerifiedPage)} />
          <Route exact path="/surveys/complete" component={withAuth(SurveyCompletePage)} />
          <Route exact path="/surveys/:uuid" component={withAuth(SurveyPage)} />
          <Route exact path="/about/terms" component={TermsPage} />
          <Route exact path="/counselings/reservation" component={withAuth(CounselingReservationPage)} />
          <Route exact path="/counselings/complete" component={withAuth(CounselingReservationCompletePage)} />
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </MuiThemeProvider>
  </AppProvider>,
  document.getElementById('app'),
);
