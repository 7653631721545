import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl, FormControlLabel, FormLabel, makeStyles, Radio, RadioGroup, Theme, createStyles, Box,
} from '@material-ui/core';
import useForm from 'react-hook-form';
import { AnswerState } from '../redux/survey_result/SurveyResultState';
import { surveyResultSelector } from '../foundation/Store';
import { createAnswer, updateAnswer } from '../redux/survey_result/SurveyResultAction';
import { ChoiceResponse } from '../generated';

export interface RadioQuestion {
  id: number;
  title: string;
  type: 'radio';
  required: boolean;
  choices: Array<ChoiceResponse>; // TODO 型つける
}

interface IProps {
  question: RadioQuestion;
  index: number;
  formMethods: ReturnType<typeof useForm>;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '& span': {
      fontSize: '0.8rem',
      padding: '0',
      margin: '0',
    },
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  radio: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },

  },
  label: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
}));

const Content = ({ question, index, formMethods }: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register } = formMethods;
  const answer: AnswerState | undefined = useSelector(surveyResultSelector).answers
    .find(item => item.questionId === question.id);
  const radioRef = question.required ? register({ required: '※どれか1つを選択してください' }) : register;

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const state: AnswerState = {
      questionId: question.id,
      choiceId: Number(event.target.value),
    };
    if (answer == null) {
      dispatch(createAnswer(state));
    } else {
      dispatch(updateAnswer(state));
    }
  }

  return (
    <FormControl className={classes.root}>
      <Box whiteSpace="pre-line">
        <FormLabel>
          {`Q${index + 1}: ${question.title}`}
        </FormLabel>
      </Box>
      <RadioGroup row className={classes.radio} onChange={handleChange} value={String(answer && answer.choiceId) || ''}>
        {
          question.choices.map((choice: ChoiceResponse) => (
            <FormControlLabel
              name={`q${index + 1}`}
              value={choice.id}
              control={<Radio color="primary" />}
              label={choice.title}
              checked={answer && answer.choiceId === choice.id}
              inputRef={radioRef}
              className={classes.label}
            />
          ))
        }
      </RadioGroup>
    </FormControl>
  );
};

export const RadioQuestionForm = Content;
