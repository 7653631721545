import React, { useEffect, useState } from 'react';
import {
  Box, makeStyles, createStyles, Paper, Typography,
} from '@material-ui/core';
import {
  format,
} from 'date-fns';
import ja from 'date-fns/locale/ja';
import { usePrivateApi } from '../api/useApi';
import {
  CounselingApi, CounselingReservationResponse,
} from '../generated';

const useStyles = makeStyles(() => createStyles({
  paper: {
    margin: 'auto',
    maxWidth: 400,
    padding: 20,
    marginTop: 20,
  },
  title: {
    textAlign: 'center',
  },
}));

const Content = () => {
  const { api: counselingApi } = usePrivateApi(CounselingApi);
  const [reservation, setReservation] = useState<CounselingReservationResponse>();

  useEffect(() => {
    counselingApi.counselingsMyselfGet().then(res => {
      setReservation(res.data);
    });
  }, []);

  const classes = useStyles();

  if (reservation === undefined) {
    return (<><Paper className={classes.paper} /></>);
  }
  return (
    <Paper className={classes.paper}>
      <h2 className={classes.title}>調整が完了しました</h2>
      <Box>
        <Typography>
面談予定：
          {reservation.reservationTimestamp ? format(new Date(reservation.reservationTimestamp), 'PPPP Ho時', { locale: ja }) : '無記入'}
        </Typography>
        <Typography>
緊急連絡先：
          {reservation.emergencyContact}
        </Typography>
        <Typography>
備考欄：
          {reservation.employeeComment}
        </Typography>
      </Box>
    </Paper>
  );
};

export const CounselingReservationCompletePage = Content;
