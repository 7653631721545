/* eslint-disable max-len */
import React from 'react';
import { Box } from '@material-ui/core';
import { MainLayout } from '../layouts';

const Content = () => (
  <Box
    style={{
      background: 'white',
      width: '80%',
      padding: '20px 40px',
      margin: '0 auto',
    }}
  >
    <h2>Mindex利用企業規約</h2>
    <p>この利用規約(以下「本規約」といいます)は、エムスリー株式会社(以下「当社」といいます)が提供するサービスである「Mindex」(以下「本サービス」といいます)の利用条件を定めるものです。</p>
    <h3>第1条 (目的)</h3>
    <p>本規約は、当社が定める手続きに従って本サービスの利用契約(以下「利用契約」といいます)を当社との間で締結した法人(以下「利用企業」といいます)と当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。</p>
    <h3>第2条 (利用契約)</h3>
    <p>利用契約は、本サービスの利用企業となることを希望する法人が、当社に対し当社所定の利用申込書を提出し当社が承諾の意思表示をすることによって、当該法人と当社との間に成立するものとします。</p>
    <h3>第3条 (利用料金)</h3>
    <p>利用企業は、利用契約に定める利用料金を、利用契約に定める支払期日までに、当社が指定する方法により当社に支払うものとします。</p>
    <h3>第4条 (従業員の登録および異動の届出)</h3>
    <p>
      <ol>
        <li>利用企業は、入会時に従業員(本サービスの適用対象となる利用企業の役職員その他の構成員をいいます。以下同じ)の登録を行い、また、以後の異動について届出を行うものとします。</li>
        <li>前項に基づく従業員の登録および異動については、当社が定める手続きによるものとします。</li>
      </ol>
    </p>

    <h3>第5条 (本サービス)</h3>
    <p>1. 本サービスの概要は以下の各号のとおりとします。</p>
    <ol style={{ listStyle: 'none' }}>
      <li>(1) 当社による従業員に対する職務等に関するアンケート(以下「本件アンケート」といいます)の実施</li>
      <li>(2) 本件アンケートの結果に基づく当社によるアラートリストの提供</li>
      <li>(3) アラートリストに基づき利用企業が抽出した従業員(以下「対象者」といいます)に対する面談の設定調整</li>
      <li>(4) 面談を希望する対象者に対する面談の実施</li>
      <li>(5) 前号の面談結果に関するレポートの提供</li>
    </ol>
    <p>2. 当社は本サービスの一部を提携先に委託することができるものとします。なお、この場合、提携先の行為について当社は利用企業に対して責任を負うものとします。</p>


    <h3>第6条 (本サービスの利用期間)</h3>
    <ol>
      <li>本サービスの利用期間は、別途利用契約に定める場合を除き、利用開始日から半年間とし、期間満了の2ヵ月前までに、当社または利用企業が当社に対し本契約を終了する旨の意思表示がない場合には、更に半年間延長され、以後も同様とします。</li>
      <li>当社は、当社の事業活動のために、利用企業が本サービスを利用していることを第三者に開示することができるものとします。</li>
      <li>当社は、利用期間の延長にあたり、第3条に定める利用料金を改定することがあります。この場合、利用企業が改定後の料金が記載された利用申込書を提出しない場合は、第1項の規定にかかわらず、本契約は利用期間の満了をもって終了するものとします。</li>
    </ol>
    <h3>第7条 (中途解約) </h3>
    <p>利用企業は、本サービスの利用期間中においても、当社に書面により通知することで、いつでも利用契約を解約できます。なお、この場合でも、利用企業は利用期間末日までの利用料金の支払い義務を免れないものとします。また、本条の解約が行われた場合でも、当社は利用企業に対して支払い済みの利用料金を返金しないものとします。</p>
    <h3>第8条 (利用契約の解除)</h3>
    <p>1. 当社は、次のいずれかの場合には、利用企業の利用契約を解除することができるものとします。</p>
    <ol style={{ listStyle: 'none' }}>
      <li>(1) 利用企業が倒産またはそれに準ずる事態、解散、営業停止処分等により事業を停止し、またはその恐れが生じたとき</li>
      <li>(2) 利用企業が正当な理由なく2ヶ月以上に亘り利用料金の支払を遅延したとき、またはその他本規約に定める事項、その他の規約若しくは特約等について違反したとき</li>
      <li>(3) 利用企業が提出した利用申込書の内容に虚偽の記載があったとき</li>
      <li>(4) 従業員が不適正なサービス利用(本規約および当社が別途定める従業員の義務への違反を含むが、これに限られない)により本サービスの正常な運営を妨げまたは当社または本サービスの信用を傷つけていると当社が判断したとき</li>
    </ol>
    <p>2. 利用企業は、当社との利用契約が終了した場合は、当該利用企業に属する従業員に対して、自らの責任により、利用契約終了について必要な範囲で周知を行うものとします。</p>
    <h3>第9条 (個人情報の取扱い)</h3>
    <ol>
      <li>
当社は、利用企業登録に際して利用企業または従業員より届け出られた従業員本人を識別する情報(以下「個人情報」という)を厳に秘密として管理し、利用企業および従業員の事前の承諾なく第三者に提供または開示しないものとします。ただし、次のいずれかの場合には、利用企業および従業員の事前の同意なくして当社は、第三者に対して個人情報を提供または開示できるものとします。
        <ol style={{ listStyle: 'none' }}>
          <li>(1) 法令に基づく場合</li>
          <li>(2) 公衆の衛生の向上、人の生命、身体または財産の保護のために必要がある場合であって、利用企業および従業員の同意を得ることが困難であるとき</li>
          <li>(3) 国の機関若しくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用企業および従業員の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
          <li>(4) 個人情報保護法に違反しない方法で提供する場合</li>
          <li>(5) 第5条第2項に基づく委託先の提携企業に対して委託業務のために必要な範囲において提供する場合</li>
        </ol>
      </li>
      <li>
当社は、個人情報を次の目的に限定して利用するものとします。
        <br />
(1) 当社が利用企業および従業員に本サービスを提供するため
        <br />
(2) 利用企業情報の管理
        <br />
(3) その他本サービス提供に必要な業務
      </li>
      <li>第1項の規定にかかわらず、当社は、前項の利用目的の達成に必要な範囲内において、個人情報の取扱いを第三者に対して業務委託する場合があります。</li>
      <li>第1項の規定にかかわらず、当社は、従業員にかかる個人情報について、法律の範囲内で利用企業と共有する場合があります。</li>
      <li>当社は、利用契約が終了した場合は、利用企業から受領した個人情報を消去または廃棄するものとします。ただし、当社は、消去または廃棄が技術上困難である場合は、消去または廃棄の義務を負わないものとします。なお、この場合、当社は、不正使用を防止するための安全管理措置を講じるものとします。</li>
      <li>利用企業は、従業員の登録を行う前に、当社が本サービス提供の目的のために従業員にかかる個人情報を取得し、また本条第1項、第3項および第4項に基づいて第三者に個人情報を提供または開示をすることがあることについて、従業員から承諾を得ているものとします。</li>
    </ol>
    <h3>第10条 (守秘義務)</h3>
    <p>当社および利用企業は、本サービスの提供を通じて知り得た相手方の営業上または技術上の機密情報(情報を開示する当事者が開示の時点で秘密である旨を相手方に告知したものに限ります)について守秘義務を負うものとします。ただし、次のいずれかに該当する場合はこの限りではないものとします。</p>
    <ol style={{ listStyle: 'none' }}>
      <li>(1) 相手方から事前の承諾がある場合</li>
      <li>(2) 弁護士、会計士、税理士その他アドバイザー等に開示する場合</li>
      <li>
(3) 知り得た機密情報が次の一に該当する場合
        <ol style={{ listStyle: 'none' }}>
          <li>1 開示を受けたときに既に公知であった情報</li>
          <li>2 開示を受けたときに既に自己が保有していた情報</li>
          <li>3 開示を受けた後に第三者から適法に取得した情報</li>
          <li>4 開示を受けた後に、開示を受けた当社または利用企業の責によらずに公知となった情報</li>
          <li>5 開示を受けた機密情報を使用せずに開示を受けた当社または利用企業が独自に開発した情報</li>
          <li>6 裁判所または行政庁により適法に開示を求められた情報その他法令により開示が義務づけられる情報</li>
        </ol>
      </li>
    </ol>
    <h3>第11条 (サービス提供責任)</h3>
    <ol>
      <li>当社は、善良な管理者の注意をもって良質なサービスの提供に努めるものとします。</li>
      <li>本サービスにおける面談は、カウンセリングの提供を目的としているものであり、診療行為又はこれに準ずる行為を目的として利用することはできません。利用企業はこのことを十分認識した上で自己の責任において本サービスを利用し、必要ならば適切な医療機関の受診等、従業員自身の判断で行わせるものとします。</li>
      <li>当社または提携企業が提供する本サービスの利用の結果、利用企業または従業員が損害を被った場合において、利用企業は、当該損害が当社の故意または重大な過失に基づく場合を除き、当社に対して何らの請求も行いえないものとします。</li>
    </ol>
    <h3>第12条 (従業員の義務)</h3>
    <ol>
      <li>利用企業は、従業員に対し本規約および当社が別途定める従業員の義務を遵守させるものとします。</li>
      <li>
従業員は、本サービスの利用にあたり、以下の行為を行ってはならないものとします。
        <ol style={{ listStyle: 'none' }}>
          <li>(1) 他の利用企業、他の従業員、第三者、または当社に不利益もしくは損害を与える行為、またはそれらの虞のある行為</li>
          <li>(2) 犯罪的行為もしくは犯罪的行為に結びつく行為、またはその虞のある行為</li>
          <li>(3) 当社の承認なく、本サービスを通じて、もしくは本サービスに関連して営利を目的とした行為、またはその準備を目的とした行為</li>
          <li>(4) 本サービスの運営を妨げる行為</li>
          <li>(5) 本サービスの信用を毀損する行為</li>
          <li>(6) 他人になりすまして本サービスを利用する行為</li>
          <li>(7) その他、法令に違反する、または違反する虞のある行為</li>
          <li>(8) その他、当社が不適切と判断する行為</li>
        </ol>
      </li>
      <li>当社は、従業員が本規約および当社が別途定める従業員の義務のいずれかに違反した場合、その他、当社が本サービスの利用を適当でないと判断した場合は、当該従業員による本サービスの全部もしくは一部の利用を制限することができるものとします。</li>
      <li>当社は、前項に基づき当社が行った行為により利用企業または従業員に生じた損害について、一切の責任を負わないものとします。</li>
    </ol>
    <h3>第13条 (本サービスの提供の停止等)</h3>
    <ol>
      <li>
当社は、以下のいずれかの事由があると判断した場合、利用企業または従業員に事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
        <ol style={{ listStyle: 'none' }}>
          <li>(1) 本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</li>
          <li>(2) 地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合</li>
          <li>(3) コンピュータまたは通信回線等が事故により停止した場合</li>
          <li>(4) その他、当社が本サービスの提供が困難と判断した場合</li>
        </ol>
      </li>
      <li>当社は、本サービスの提供の停止または中断により、利用企業もしくは従業員または第三者が被ったいかなる不利益または損害について、理由を問わず一切の責任を負わないものとします。</li>
      <li>当社は、経済合理性の理由により本サービスの提供が困難になった場合その他当社が必要と判断した場合は、3ヶ月の予告期間をもって従業員に通知することにより、本サービスの全部または一部の提供を終了することができるものとします。</li>
    </ol>
    <h3>第14条 (損害賠償責任)</h3>
    <p>当社は、本サービスの提供に関連して利用企業または従業員に損害を与えたときは、通常損害に限り、かつ第3条に定める利用料金の半年分を限度として責任を負うものとし、逸失利益および第三者からの損害賠償請求に基づく損害については責任を負わないものとします。</p>
    <h3>第15条 (反社会的勢力の排除)</h3>
    <ol>
      <li>当社および利用企業は、自ら(主要な出資者、役員、およびそれに準ずる者を含み、利用企業の場合は従業員を含む)が暴力団、暴力団員・準構成員、暴力団関係企業、特殊知能暴力集団の関係者その他公益に反する行為をなす者(以下「反社会的勢力」という)でないこと、過去5年間もそうでなかったことおよび反社会的勢力と資金提供、便宜供与その他いかなる関係も有しないことを表明し、かつ将来にわたっても反社会的勢力とのいかなる関係も有しないことを誓約します。</li>
      <li>当社および利用企業は、自らまたは第三者を利用して、暴力的な要求行為、法的な責任を超えた不当な要求行為、取引に関して、脅迫的な言動をし、または暴力を用いる行為、風説を流布し、偽計を用いまたは威力を用いて相手方の信用を毀損し、または相手方の業務を妨害する行為、およびその他これらに準ずる行為を行わないことを誓約します。</li>
      <li>当社および利用企業は、相手方について第1項の表明に反することが判明した場合または前2項の誓約に反した場合、当社と利用企業との契約を、何らの催告を要せず直ちに解除できるものとします。なお、この解除によって生じた損害については、解除当事者は責任を負わないものとします。</li>
    </ol>
    <h3>第16条 (利用規約の変更)</h3>
    <ol>
      <li>当社は、本利用規約を、利用企業の承諾を得ることなく変更することがあります。この場合、変更した本利用規約は、本サービスを提供するウェブサイト上に掲載・表示した時点で効力を有するものとします。</li>
      <li>前項にかかわらず、第9条(個人情報の取扱い)を変更する場合は、1か月前に利用企業に通知するものとしたうえで、通知後異議を述べた利用企業については、利用規約の変更後の対応につき個別に協議を行うものとします。</li>
    </ol>
    <h3>第17条 (通知)</h3>
    <ol>
      <li>利用企業は、自己の商号・名称、住所その他相手方に通知等を発送する際に必要となる事項に変更がある場合は、変更後すみやかに当社に通知するものとします。</li>
      <li>前項の違反その他の事情により当社が利用企業に通知等を送達させることができないときは、当社は、届出の住所に宛てて通知等を発送することにより、通常到達すべき時に当該通知等が到達したものとみなすことができるものとします。</li>
    </ol>
    <h3>第18条 (権利義務の譲渡の禁止)</h3>
    <p>利用企業は、当社の書面による事前の承諾なく、本規約に基づく地位または権利もしくは義務を第三者に譲渡し、または担保に供することはできないものとします。</p>
    <h3>第19条 (類似サービスの開発等の禁止)</h3>
    <p>利用企業は、当社の書面による事前の承諾なく、本サービスと同一または類似のサービスの開発、提供を行うことはできないものとします。なお、本条は利用企業による本サービスの利用終了後も有効に存続します。</p>
    <h3>第20条 (準拠法・裁判管轄)</h3>
    <ol>
      <li>本規約の解釈にあたっては、日本法を準拠法とします。</li>
      <li>本サービスに関して紛争が生じた場合には、東京地方裁判所を専属的合意管轄とします。</li>
    </ol>
  </Box>
);

export const TermsPage = MainLayout(Content);
