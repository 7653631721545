import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  Slider,
  FormLabel,
  makeStyles,
  Theme,
  createStyles,
  Box,
} from '@material-ui/core';
import useForm from 'react-hook-form';
import { AnswerState } from '../redux/survey_result/SurveyResultState';
import { surveyResultSelector } from '../foundation/Store';
import {
  createAnswer,
  updateAnswer,
} from '../redux/survey_result/SurveyResultAction';
import { ChoiceResponse } from '../generated';

export interface SliderQuestion {
  id: number;
  title: string;
  type: 'slider';
  required: boolean;
  choices: Array<ChoiceResponse>;
}

interface IProps {
  question: SliderQuestion;
  index: number;
  formMethods: ReturnType<typeof useForm>;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  margin: {
    height: theme.spacing(3),
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
}));

const Content = ({ question, index }: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const answer: AnswerState | undefined = useSelector(
    surveyResultSelector,
  ).answers.find(item => item.questionId === question.id);

  // TODO: 初期値をDBからもらう設計に帰る場合はdefault値を見てる部分を修正する
  const defaultValue: number = answer
    ? parseInt(question.choices[question.choices.findIndex(
      (choice: ChoiceResponse) => choice.id === (answer.choiceId),
    )].value, 10)
    : 7;

  useEffect(() => {
    // TODO: 初期値をDBからもらう設計に帰る場合はdefault値を見てる部分を修正する
    const choiceId: number = question.choices[question.choices.findIndex((choice: ChoiceResponse) => choice.value === `${defaultValue}`)].id;
    const state: AnswerState = {
      questionId: question.id,
      choiceId,
    };
    dispatch(createAnswer(state));
  }, []);

  function handleChange(event: React.ChangeEvent<{}>, value: number|number[]) {
    const choiceId = question.choices[question.choices.findIndex((choice: ChoiceResponse) => choice.title === `${value}`)].id;
    const state: AnswerState = {
      questionId: question.id,
      choiceId,
    };
    if (answer == null) {
      dispatch(createAnswer(state));
    } else {
      dispatch(updateAnswer(state));
    }
  }
  const marks = question.choices.map((choice: any) => ({
    value: choice.title, label: choice.title,
  }));

  return (
    <FormControl className={classes.root}>
      <Box whiteSpace="pre-line">
        <FormLabel>{`Q${index + 1}: ${question.title}`}</FormLabel>
      </Box>
      <Slider
        onChange={handleChange}
        classes={{ mark: classes.mark }}
        defaultValue={defaultValue}
        max={10}
        marks={marks}
      />
      <div className={classes.margin} />
    </FormControl>
  );
};

export const SliderQuestionForm = Content;
